import { AlignmentType, Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import Subtitles from '../components/Libraries/Subtitles';
import { convertToRaw, DraftInlineStyleType, EditorState, RawDraftContentState } from 'draft-js';
import { convertEditorStateToTranscript } from '../shared/DataConverters';
import useEditor from './useEditor';
import { useClipboard } from './useClipboardCopy';
import { useCallback, useEffect, useRef } from 'react';
import { covertEditorStateToHTML } from '../components/Editor/Editor';
import { useSnackbar } from 'notistack';
import { useAppSelector } from '../redux/store';

type CustomDraftInlineStyleType = DraftInlineStyleType | "OVERLAPPING"
type CustomRawDraftInlineStyleRange = {
  style: CustomDraftInlineStyleType;
  offset: number;
  length: number;
}

// DOCX formatting:
// Run formatting
// bold, italics, smallCaps, allCaps, strike, doubleStrike, subScript, superScript: Set the formatting property to true
// underline({type="single", color=null}): Set the underline style and color
// emphasisMark({type="dot"}): Set the emphasis mark style
// color(color): Set the text color, using 6 hex characters for RRGGBB (no leading #)
// size(halfPts): Set the font size, measured in half-points
// font(name) or font({ascii, cs, eastAsia, hAnsi, hint}): Set the run's font
// style(name): Apply a named run style
// characterSpacing(value): Set the character spacing adjustment (in TWIPs)

// Paragraph formatting
// heading1, heading2, heading3, heading4, heading5, title: apply the appropriate style to the paragraph
// left, center, right, justified: set the paragraph's alignment
// thematicBreak, pageBreak: Insert a thick rule or a page break beneath the paragraph
// leftTabStop(position): Add a left tab stop (measured in TWIPs from the left)
// maxRightTabStop: Add a right tab stop at the far right
// bullet: Use the default bullet style
// setNumbering(numbering, indentLevel): Use a custom numbering format for the paragraph
// style(name): Apply a named paragraph style
// indent(start, hanging=0): Set the paragraph's indent level (in TWIPs)
// spacing({before=0, after=0, line=0}): Set the line and before/after on the paragraph. Before/after is measured in TWIPs, line is measured in 240ths of a line
// Paragraph styles have all the run formatting methods, except style(), and left(), center(), right(), justified(), thematicBreak(), leftTabStop(position), maxRightTabStop(), indent(start, hanging=0), and spacing({before=0, after=0, line=0}) methods.


const editorStateArrayToDocx = async (editorStates: EditorState[]) => {
  let docxParagraphs: Paragraph[] = [];

  for (let editorStateIndex = 0; editorStateIndex < editorStates.length; editorStateIndex++) {
    const editorState = editorStates[editorStateIndex]

    const editorPs = convertToRaw(editorState.getCurrentContent());
    let prevSpeaker: any = null;

    editorPs.blocks.forEach((block, blockIndex) => {
      const speaker = block.data?.speaker;
      const blockPrefix = block.data?.blockPrefix?.value;
      //console.log(block)
      if (blockPrefix) {
  
        const firstSplit = blockPrefix.split("/")[1];
        const spaceSplit = firstSplit.split(" ") 
        const dateSplit = spaceSplit[0].split("-");
        const year = dateSplit[0]
        const month = dateSplit[1]
        const day = dateSplit[2]
      
        const timeSplit = spaceSplit[1].split("-");
        const hour = timeSplit[0]
        const minute = timeSplit[1]
        const second = timeSplit[2]
  
        if (blockIndex > 0) {
          docxParagraphs.push(new Paragraph({}));
        }
        docxParagraphs.push(new Paragraph({
          children: [
            new TextRun({
              size: 20,
              text: `${day}.${month}.${year} ${hour}:${minute}:${second}`,
              bold: true,
              underline: {},
            }),
          ],
        }));
        docxParagraphs.push(new Paragraph({}));
      }
  
      const prevBlockIsSame =
        speaker && prevSpeaker !== null && prevSpeaker && prevSpeaker.id
          ? prevSpeaker.id === speaker.id
          : false;
  
      const styles = block.inlineStyleRanges as CustomRawDraftInlineStyleRange[];
  
      const speakerParagraph =
        !prevBlockIsSame && speaker
          ? new Paragraph({
              children: [
                new TextRun({
                  size: 20,
                  text: speaker?.name || 'NEZNANI GOVOREC',
                  bold: true,
                  underline: {},
                }),
              ],
            })
          : null;
  
      for (let entityId in block.entityRanges) {
        const entityRange = block.entityRanges[entityId]
        const entity = editorPs.entityMap[entityRange.key]
        if (entity && entity.data && entity.data.isOverlapping) {
          styles.push({
            offset: entityRange.offset,
            length: entityRange.length,
            style: "OVERLAPPING",
          })
        }
      }
      if (styles.length === 0 || !styles) {
        speakerParagraph && docxParagraphs.push(new Paragraph({}));
        speakerParagraph && docxParagraphs.push(speakerParagraph);
  
        docxParagraphs.push(
          new Paragraph({
            children: [
              new TextRun({
                text: block.text,
              }),
            ],
          })
        );
      }
      let wordsArr: any[] = [];
      block.text.split('').forEach((char, i) => {
        let currChar: any = { text: char, styles: '' };
  
        styles.forEach((style, j) => {
          const start = style.offset;
          const end = style.offset + style.length;
  
          if (start <= i && end > i && style.length) {
            currChar = { ...currChar, styles: currChar.styles + style.style.charAt(0) };
          } else {
          }
        });
  
        const ind = wordsArr.length - 1;
  
        if (wordsArr.length && wordsArr[ind] && wordsArr[ind].styles === currChar.styles) {
          wordsArr[ind].text += currChar.text;
        } else {
          wordsArr.push(currChar);
        }
      });
  
      if (styles && styles.length) {
        speakerParagraph && docxParagraphs.push(new Paragraph({}));
        speakerParagraph && docxParagraphs.push(speakerParagraph);
  
        docxParagraphs.push(
          new Paragraph({
            children: wordsArr.map((word) => {
              const s = word.styles;
              const obj = {
                text: word.text,
                color: /*s.includes('O') ? "#FF0000" : */"#000000",
              }
  
              if (s.includes('B')) {
                obj["bold"] = true
              }
  
              if (s.includes('I')) {
                obj["italics"] = true
              }
  
              if (s.includes('U')) {
                obj["underline"] = {}
              }
              return new TextRun(obj);
            }),
          })
        );
      }
      prevSpeaker = speaker;
    });

  }
  
  return new Document({
    styles: {
      default: {
        document: {
          run: {
            font: "Courier New",
            size: 24, // 12pt (docx uses half-points)
          },
          paragraph: {
            //spacing: { line: 480 }, // Double spacing (240 = single)
            alignment: AlignmentType.JUSTIFIED,
          },
        },
      },
    },
    sections: [
      {
        children: docxParagraphs,
      },
    ],
  });
}


const editorStateToDocx = async (editorState: EditorState) => {
  if (!editorState) return;
  const editorPs = convertToRaw(editorState.getCurrentContent());
  let prevSpeaker: any = null;

  let docxParagraphs: Paragraph[] = [];
  editorPs.blocks.forEach((block, blockIndex) => {
    const speaker = block.data?.speaker;
    const blockPrefix = block.data?.blockPrefix?.value;
    //console.log(block)
    if (blockPrefix) {

      const firstSplit = blockPrefix.split("/")[1];
      const spaceSplit = firstSplit.split(" ") 
      const dateSplit = spaceSplit[0].split("-");
      const year = dateSplit[0]
      const month = dateSplit[1]
      const day = dateSplit[2]
    
      const timeSplit = spaceSplit[1].split("-");
      const hour = timeSplit[0]
      const minute = timeSplit[1]
      const second = timeSplit[2]

      if (blockIndex > 0) {
        docxParagraphs.push(new Paragraph({}));
      }
      docxParagraphs.push(new Paragraph({
        children: [
          new TextRun({
            size: 20,
            text: `${day}.${month}.${year} ${hour}:${minute}:${second}`,
            bold: true,
            underline: {},
          }),
        ],
      }));
      docxParagraphs.push(new Paragraph({}));
    }

    const prevBlockIsSame =
      speaker && prevSpeaker !== null && prevSpeaker && prevSpeaker.id
        ? prevSpeaker.id === speaker.id
        : false;

    const styles = block.inlineStyleRanges as CustomRawDraftInlineStyleRange[];

    const speakerParagraph =
      !prevBlockIsSame && speaker
        ? new Paragraph({
            children: [
              new TextRun({
                size: 20,
                text: speaker?.name || 'NEZNANI GOVOREC',
                bold: true,
                underline: {},
              }),
            ],
          })
        : null;

    for (let entityId in block.entityRanges) {
      const entityRange = block.entityRanges[entityId]
      const entity = editorPs.entityMap[entityRange.key]
      if (entity && entity.data && entity.data.isOverlapping) {
        styles.push({
          offset: entityRange.offset,
          length: entityRange.length,
          style: "OVERLAPPING",
        })
      }
    }
    if (styles.length === 0 || !styles) {
      speakerParagraph && docxParagraphs.push(new Paragraph({}));
      speakerParagraph && docxParagraphs.push(speakerParagraph);

      docxParagraphs.push(
        new Paragraph({
          children: [
            new TextRun({
              text: block.text,
            }),
          ],
        })
      );
    }
    let wordsArr: any[] = [];
    block.text.split('').forEach((char, i) => {
      let currChar: any = { text: char, styles: '' };

      styles.forEach((style, j) => {
        const start = style.offset;
        const end = style.offset + style.length;

        if (start <= i && end > i && style.length) {
          currChar = { ...currChar, styles: currChar.styles + style.style.charAt(0) };
        } else {
        }
      });

      const ind = wordsArr.length - 1;

      if (wordsArr.length && wordsArr[ind] && wordsArr[ind].styles === currChar.styles) {
        wordsArr[ind].text += currChar.text;
      } else {
        wordsArr.push(currChar);
      }
    });

    if (styles && styles.length) {
      speakerParagraph && docxParagraphs.push(new Paragraph({}));
      speakerParagraph && docxParagraphs.push(speakerParagraph);

      docxParagraphs.push(
        new Paragraph({
          children: wordsArr.map((word) => {
            const s = word.styles;
            const obj = {
              text: word.text,
              color: /*s.includes('O') ? "#FF0000" : */"#000000",
            }

            if (s.includes('B')) {
              obj["bold"] = true
            }

            if (s.includes('I')) {
              obj["italics"] = true
            }

            if (s.includes('U')) {
              obj["underline"] = {}
            }
            return new TextRun(obj);
          }),
        })
      );
    }
    prevSpeaker = speaker;
  });

  return new Document({
    styles: {
      default: {
        document: {
          run: {
            font: "Courier New",
            size: 24, // 12pt (docx uses half-points)
          },
          paragraph: {
            //spacing: { line: 480 }, // Double spacing (240 = single)
            alignment: AlignmentType.JUSTIFIED,
          },
        },
      },
    },
    sections: [
      {
        children: docxParagraphs,
      },
    ],
  });
};

export default function useDownload() {
  const { editorState } = useEditor();
  const audioUrl = useAppSelector(state => state.app.audioInfo.url);
  const sessionName = useAppSelector(state => state.app.sessionName);
  const { enqueueSnackbar } = useSnackbar();

  const handleDownloadDocx = useCallback(async () => {
    if (!editorState) return;

    const docx = await editorStateToDocx(editorState);
    if (!docx) return;

    Packer.toBlob(docx).then((blob) => {
      const filename = sessionName ? sessionName + '.docx' : 'transkript.docx';
      saveAs(blob, filename);
    });
  }, [editorState, sessionName]);
  
  const handleDownloadDocxFromState = useCallback(async (state: EditorState[], sessionName: string) => {
    const docx = await editorStateArrayToDocx(state);
    if (!docx) return;

    Packer.toBlob(docx).then((blob) => {
      saveAs(blob, sessionName + '.docx');
    });
  }, [sessionName]);

  const { copy, copied } = useClipboard({
    copiedTimeout: 1750,
  });
  const htmlCopied = useRef(false);

  useEffect(() => {
    if (copied) {
      enqueueSnackbar(
        htmlCopied.current
          ? 'HTML vsebina uspešno kopirana v odložišče.'
          : 'Besedilo uspešno kopirano v odložišče.',
        { variant: 'success' }
      );
      htmlCopied.current = false;
    }
  }, [copied]);

  const handleCopyTextToClipBoard = useCallback(
    (isHtml?: boolean) => {
      if (isHtml) {
        const html = covertEditorStateToHTML(editorState);

        copy(html);
        htmlCopied.current = true;
        return;
      }

      let textToCopy = '';

      const raw = convertToRaw(editorState.getCurrentContent());
      let prevSpeaker: any = null;

      raw.blocks.forEach((b) => {
        const speaker = b.data?.speaker;

        const prevBlockIsSame =
          speaker && prevSpeaker !== null && prevSpeaker && prevSpeaker.id
            ? prevSpeaker.id === speaker.id
            : false;

        textToCopy = `${textToCopy}${
          speaker && !prevBlockIsSame ? `\n${speaker?.name}\n${b.text}` : b.text
        }\n`;
        prevSpeaker = speaker;
      });

      copy(textToCopy);
    },
    [editorState]
  );

  const handleDownloadSrt = useCallback(() => {
    if (!editorState) return;
    const { transcript } = convertEditorStateToTranscript(editorState, { toLiveWordData: true });
    Subtitles(transcript, sessionName);
  }, [editorState, sessionName]);

  const handleDownloadRaw = useCallback(() => {
    if (!editorState) return;
    const tbFormat: RawDraftContentState = convertToRaw(editorState.getCurrentContent());
    const rawFormatBlob = new Blob([JSON.stringify(tbFormat)], {
      type: 'application/json',
    });
    saveAs(rawFormatBlob, sessionName + '.raw');
  }, [editorState, sessionName]);

  const handleDownloadTb = useCallback(async () => {
    if (!editorState) return;

    // const { liveWordTranscript } = convertEditorStateToTranscript(editorState, { toLiveWordData: true });

    let tbFormatBlob = new Blob(
      [JSON.stringify({ rawContentState: convertToRaw(editorState.getCurrentContent()) })],
      {
        type: 'application/json',
      }
    );
    // TO-DO: Let use insert the audio name
    const fileName = sessionName ? sessionName : 'live_recording';

    saveAs(tbFormatBlob, fileName + '.tb');
  }, [sessionName, editorState]);

  const handleDownloadAudio = useCallback(async () => {
    const fileName = sessionName ? sessionName : 'Untitled session';
    saveAs(audioUrl, `${fileName}.wav`);
  }, [sessionName]);

  return {
    handleDownloadDocxFromState,
    handleDownloadDocx,
    handleDownloadSrt,
    handleDownloadTb,
    handleDownloadRaw,
    handleCopyTextToClipBoard,
    handleDownloadAudio,
  };
}
